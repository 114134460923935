import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { HeatloadService } from '../../heatload/services/heatload.service';
import { catchError, switchMap, filter, map, EMPTY, auditTime, withLatestFrom } from 'rxjs';
import { HeatloadActions } from './heatload.actions';
import { EnergySource, HeatGeneratorType, HeatLoadCalculationMethod } from '@customer-apps/shared/enums';
import { HeatloadStore } from './heatload.store';
import { ModernizationsAndInsulationFormValue } from '@customer-apps/shared/interfaces';

@Injectable()
export class HeatloadEffects {
    constructor(private actions$: Actions, private heatloadService: HeatloadService, private heatloadStore: HeatloadStore) {}

    public getHeatGenerators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.submitCalculationMethod),
            auditTime(10),
            filter(formValue => formValue.methods.standardMethod === HeatLoadCalculationMethod.Consumption),
            switchMap(() =>
                this.heatloadService.getHeatGenerators().pipe(
                    map(generators => HeatloadActions.getHeatGeneratorsSuccess({ generators })),
                    catchError(error => EMPTY)
                )
            )
        )
    );

    public getWindows$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.submitModernizationsAndInsulation),
            auditTime(10),
            switchMap(formValue =>
                this.heatloadService
                    .getWindows(
                        (formValue as ModernizationsAndInsulationFormValue).windowsConstructionYear,
                        (formValue as ModernizationsAndInsulationFormValue).buildingConstructionYear
                    )
                    .pipe(catchError(error => EMPTY))
            ),
            map(windows => HeatloadActions.getWindowsSuccess({ windows }))
        )
    );

    public getHeatingSystems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatloadActions.getHeatingSystems),
            withLatestFrom(this.heatloadStore.energySource$, (action, energySourceState) => ({ action, energySourceState })),
            switchMap(({ action, energySourceState }) =>
                this.heatloadService
                    .getHeatingSystems(
                        energySourceState!.selectedEnergySource!.type as EnergySource,
                        +action.installationYear,
                        energySourceState!.selectedHeatGenerator as HeatGeneratorType
                    )
                    .pipe(catchError(error => EMPTY))
            ),
            map(heatingSystems => HeatloadActions.getHeatingSystemsSuccess({ heatingSystems }))
        )
    );
}
