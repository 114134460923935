import { createSelector } from '@ngrx/store';
import { Address, ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import { AppState, ProjectState } from '@customer-apps/shared/interfaces';
import { ProductType } from '@customer-apps/shared/enums';
import { ProjectUtils } from '@customer-apps/shared/utils';

export namespace ProjectSelectors {
    const projectState = (state: AppState) => state.project;

    export const getState = () => createSelector(projectState, (state: ProjectState) => state);

    export const selectProject = () => createSelector(projectState, (state: ProjectState) => state?.selectedProject);

    export const selectAddressCollision = () => createSelector(projectState, (state: ProjectState) => state?.addressCollision);

    export const selectHasSystemComponentsOrHeatloads = () =>
        createSelector(projectState, (state: ProjectState) => {
            const project = state.selectedProject as ProjectResponseGetById;
            const plannings = ProjectUtils.getPlanningsWithProducts(project);
            const products = plannings
                ?.map(planning => planning?.products?.filter(product => product.productType !== ProductType.Other))
                .flat(1);
            return products?.length! > 0 || project?.building?.heatLoadCalculations?.length! > 0;
        });

    export const selectAddress = () =>
        createSelector(projectState, (state: ProjectState) => {
            const project = state.selectedProject;
            return {
                city: project?.address.city,
                countryCode: project?.address.countryCode,
                houseNumber: project?.address.houseNumber,
                postalCode: project?.address.postalCode,
                streetName: project?.address.streetName
            } as Address;
        });
}
