import { createReducer, on } from '@ngrx/store';
import { FormKeys } from '@customer-apps/shared/enums';
import { Device, HeatPumpIntermediaryState } from '@customer-apps/shared/interfaces';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { HeatPumpIntermediaryActions } from './heat-pump-intermediary.actions';
import { percentToFraction } from '@customer-apps/shared/utils';
import { LodashService } from '@customer-apps/shared/services';

export const initialState: HeatPumpIntermediaryState = {};

export const heatPumpIntermediaryReducer = createReducer(
    initialState,
    on(HeatPumpIntermediaryActions.submitDevice, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...initialState,
            isOldDeviceUsed: { value: (<Device>formValue)[FormKeys.IsOldDeviceUsed] }
        };
    }),
    on(HeatPumpIntermediaryActions.submitSubsidyEligibility, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...state,
            subsidyEligibility: {
                isIncomeLowForSubsidy: { value: formValue[FormKeys.IsIncomeLowForSubsidy] },
                isOwnerOfTheBuilding: { value: formValue[FormKeys.IsOwnerOfTheBuilding] },
                isOwnerMainResidence: { value: formValue[FormKeys.IsOwnerMainResidence] },
                isOldHeatingSystemDefective: LodashService.isNil(formValue[FormKeys.IsOldHeatingSystemDefective])
                    ? undefined
                    : { value: formValue[FormKeys.IsOldHeatingSystemDefective]! },
                numberOfResidentialUnits:
                    formValue[FormKeys.NumberOfResidentialUnits] !== undefined
                        ? { value: formValue[FormKeys.NumberOfResidentialUnits] }
                        : undefined
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitBuilding, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...state,
            heatingEnergySource: undefined,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails: undefined,
            building: formValue
        };
    }),
    on(
        HeatPumpIntermediaryActions.getHeatGeneratorsSuccess,
        (state, { heatGenerators, selectedEnergySourceType, selectedHeatGeneratorType }): HeatPumpIntermediaryState => {
            if (selectedEnergySourceType && selectedHeatGeneratorType) {
                return {
                    ...state,
                    heatGenerators,
                    heatingEnergySource: {
                        selectedEnergySource: getSelectedSourceFromGenerators(
                            heatGenerators,
                            selectedHeatGeneratorType,
                            selectedEnergySourceType
                        ),
                        selectedHeatGenerator: selectedHeatGeneratorType
                    }
                };
            }

            return { ...state, heatGenerators };
        }
    ),
    on(HeatPumpIntermediaryActions.getConsumptionProfilesSuccess, (state, { consumptionProfiles }): HeatPumpIntermediaryState => {
        return { ...state, consumptionProfiles };
    }),
    on(HeatPumpIntermediaryActions.resetHeatPumpIntermediary, (): HeatPumpIntermediaryState => {
        return { ...initialState };
    }),
    on(HeatPumpIntermediaryActions.submitHeatGenerator, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails: undefined,
            heatingEnergySource: {
                selectedEnergySource: getSelectedSourceFromGenerators(
                    state.heatGenerators!,
                    formValue.heatGenerator,
                    formValue.specificEnergySource
                ),
                selectedHeatGenerator: formValue.heatGenerator
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitHeatGeneratorDetails, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            heatGeneratorDetails: {
                boilerType: formValue.boilerType,
                heatPumpOperatingMode: formValue?.heatPumpOperatingMode,
                lowTemperature: formValue.boilerTypeSubquestion?.lowTemperature,
                warmWaterByHeatGenerator: formValue.boilerTypeSubquestion?.warmWaterByHeatGenerator,
                gasLossExhaust: percentToFraction(formValue.gasLossExhaust),
                gasLossExhaustKnown: formValue.gasLossExhaustKnown?.isKnown,
                installationYear: formValue.installationYear,
                nominalPower: formValue.nominalPower
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitEnergyConsumption, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...state,
            heatingDistribution: undefined,
            consumptionProfiles: undefined,
            energyConsumption: {
                requiredEnergyPerYear: formValue.requiredEnergyPerYear,
                nonHeatingElectricityPerYear: formValue.nonHeatingElectricityPerYear
            }
        };
    }),
    on(HeatPumpIntermediaryActions.submitHeatingDistribution, (state, formValue): HeatPumpIntermediaryState => {
        return {
            ...state,
            heatingDistribution: {
                distributionMethod: formValue.distributionMethod,
                heatingFlowTemperature: formValue.heatingFlowTemperature,
                heatingSchedule: formValue.heatingSchedule
            }
        };
    }),
    on(HeatPumpIntermediaryActions.getHeatingSystemsSuccess, (state, action) => {
        return { ...state, heatingSystems: action.heatingSystems };
    }),
    on(HeatPumpIntermediaryActions.submitSameValue, (state, action) => {
        const { formName, value } = action;
        return { ...state, sameValueSubmited: { formName, value } };
    }),
    on(HeatPumpIntermediaryActions.resetSameValue, state => {
        return { ...state, sameValueSubmited: undefined };
    })
);
