import { createReducer, on } from '@ngrx/store';
import { percentToFraction } from '@customer-apps/shared/utils';
import { GEGSystemType, HeatPumpOperatingMode, HeatingSystemType } from '@customer-apps/shared/enums';
import { GEGState } from '@customer-apps/shared/interfaces';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { GEGActions } from './geg.actions';
import { getLowTemperatureForNewSystem } from './new-system-low-temperature.helper';

export const initialState: GEGState = {};

export const GEGReducer = createReducer(
    initialState,
    on(GEGActions.submitSystem, (state, formValue) => {
        return { ...initialState, system: { value: formValue.system } };
    }),
    on(GEGActions.extendSystem, state => {
        return { ...state, system: { value: GEGSystemType.Hybrid } };
    }),
    on(GEGActions.submitCurrentSystemHeatGenerator, (state, formValue) => {
        return {
            ...state,
            currentSystem: {
                ...state.currentSystem,
                heatGeneratorDetails: undefined,
                energySource: {
                    selectedEnergySource: getSelectedSourceFromGenerators(
                        state.heatGenerators!,
                        formValue.heatGenerator,
                        formValue.specificEnergySource
                    ),
                    selectedHeatGenerator: formValue.heatGenerator
                }
            }
        };
    }),
    on(GEGActions.submitNewSystemHeatGenerator, (state, formValue) => {
        return {
            ...state,
            newSystem: {
                heatGeneratorDetails: undefined,
                energySource: {
                    selectedEnergySource: getSelectedSourceFromGenerators(
                        state.heatGenerators!,
                        formValue.heatGenerator,
                        formValue.specificEnergySource
                    ),
                    selectedHeatGenerator: formValue.heatGenerator
                }
            }
        };
    }),
    on(GEGActions.getHeatingSystemForNewSystem, (state, action) => {
        return {
            ...state,
            newSystem: {
                ...state.newSystem,
                heatGeneratorDetails: {
                    installationYear: action.installationYear
                }
            }
        };
    }),
    on(GEGActions.getHeatingSystemForNewSystemSuccess, (state, action) => {
        return {
            ...state,
            newSystem: {
                ...state.newSystem,
                heatGeneratorDetails: {
                    ...state.newSystem?.heatGeneratorDetails,
                    boilerType: action.heatingSystem.type as HeatingSystemType,
                    heatPumpOperatingMode: action.heatingSystem.operatingModes?.[0] as HeatPumpOperatingMode,
                    lowTemperature: getLowTemperatureForNewSystem(action.heatingSystem)
                }
            }
        };
    }),
    on(GEGActions.evaluateGEGSuccess, (state, action) => {
        return {
            ...state,
            evaluation: action.evaluation
        };
    }),
    on(GEGActions.submitCurrentSystemHeatGeneratorDetails, (state, formValue) => {
        return {
            ...state,
            currentSystem: {
                ...state.currentSystem,
                heatGeneratorDetails: {
                    boilerType: formValue.boilerType,
                    heatPumpOperatingMode: formValue?.heatPumpOperatingMode,
                    lowTemperature: formValue.boilerTypeSubquestion?.lowTemperature,
                    warmWaterByHeatGenerator: formValue.boilerTypeSubquestion?.warmWaterByHeatGenerator,
                    gasLossExhaust: percentToFraction(formValue.gasLossExhaust),
                    gasLossExhaustKnown: formValue.gasLossExhaustKnown?.isKnown,
                    installationYear: formValue.installationYear,
                    nominalPower: formValue.nominalPower
                }
            }
        };
    }),
    on(GEGActions.getHeatGeneratorsSuccess, (state, action) => {
        return { ...state, heatGenerators: [...action.generators] };
    }),
    on(GEGActions.getHeatingSystemsForCurrentSystemSuccess, (state, action) => {
        return {
            ...state,
            currentSystem: {
                ...state.currentSystem,
                heatingSystems: action.heatingSystems
            }
        };
    }),
    on(GEGActions.resetGEG, () => initialState),
    on(GEGActions.resetEvaluatedGEGResponse, state => {
        return {
            ...state,
            evaluation: undefined
        };
    }),
    on(GEGActions.cacheState, state => {
        return {
            ...state,
            cache: state
        };
    }),
    on(GEGActions.restoreStateFromCache, state => {
        return state.cache || state;
    }),
    on(GEGActions.submitSameValue, (state, action) => {
        const { formName, value } = action;
        return { ...state, sameValueSubmited: { formName, value } };
    }),
    on(GEGActions.resetSameValue, state => {
        return { ...state, sameValueSubmited: undefined };
    })
);
