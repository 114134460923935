import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorFormValue,
    SameFormValue,
    SystemFormValue
} from '@customer-apps/shared/interfaces';
import { GEGSystemType } from '@customer-apps/shared/enums';

import { GEGSelectors } from './geg.selectors';
import { GEGActions } from './geg.actions';

@Injectable({ providedIn: 'root' })
export class GEGStore {
    public state$ = this.store$.pipe(select(GEGSelectors.getState()));
    public system$ = this.store$.pipe(select(GEGSelectors.getSystem()));
    public currentSystemEnergySource$ = this.store$.pipe(select(GEGSelectors.getCurrentSystemEnergySource()));
    public newSystemEnergySource$ = this.store$.pipe(select(GEGSelectors.getNewSystemEnergySource()));
    public heatGenerators$ = this.store$.pipe(select(GEGSelectors.getHeatGenerators()));
    public currentSystemHeatingSystems$ = this.store$.pipe(select(GEGSelectors.getCurrentSystemHeatingSystems()));
    public currentSystemHeatGeneratorDetails$ = this.store$.pipe(select(GEGSelectors.getCurrentSystemHeatGeneratorDetails()));
    public gegEvaluationRequest$ = this.store$.pipe(select(GEGSelectors.getGEGEvaluationRequest()));
    public gegEvaluationResponse$ = this.store$.pipe(select(GEGSelectors.getGEGEvaluationResponse()));
    public sameValueSubmited$ = this.store$.pipe(select(GEGSelectors.sameValueSubmited()));

    constructor(private store$: Store<AppState>) {}

    public submitSystem(system: SystemFormValue): void {
        this.store$.dispatch(GEGActions.submitSystem(system));
    }

    public extendSystem(): void {
        this.store$.dispatch(GEGActions.extendSystem());
    }

    public submitCurrentSystemHeatGenerator(system: HeatGeneratorFormValue): void {
        this.store$.dispatch(GEGActions.submitCurrentSystemHeatGenerator(system));
    }

    public submitCurrentSystemHeatGeneratorDetails(system: HeatGeneratorDetailsFormValue): void {
        this.store$.dispatch(GEGActions.submitCurrentSystemHeatGeneratorDetails(system));
    }

    public submitNewSystemHeatGenerator(system: HeatGeneratorFormValue): void {
        this.store$.dispatch(GEGActions.submitNewSystemHeatGenerator(system));
    }

    public geHeatGenerators(systemType: GEGSystemType): void {
        this.store$.dispatch(GEGActions.getHeatGenerators({ systemType }));
    }

    public getHeatingSystemsForCurrentSystem(installationYear: string): void {
        this.store$.dispatch(GEGActions.getHeatingSystemsForCurrentSystem({ installationYear }));
    }

    public resetGEG(): void {
        this.store$.dispatch(GEGActions.resetGEG());
    }

    public cacheState(): void {
        this.store$.dispatch(GEGActions.cacheState());
    }

    public restoreStateFromCache(): void {
        this.store$.dispatch(GEGActions.restoreStateFromCache());
    }

    public resetEvaluatedGEGResponse(): void {
        this.store$.dispatch(GEGActions.resetEvaluatedGEGResponse());
    }

    public submitSameValue(form: SameFormValue): void {
        this.store$.dispatch(GEGActions.submitSameValue(form));
    }

    public resetSameValue(): void {
        this.store$.dispatch(GEGActions.resetSameValue());
    }
}
