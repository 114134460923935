import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import { ApplicationId, ExternalTool, MigrationIds, MigrationSectionType, ProjectStatus, RouterLinks } from '@customer-apps/shared/enums';
import { MigrationVerification } from '@customer-apps/shared/interfaces';
import { ProjectUtils, Queue, applicationIdToPhraseKey, hasSharedItems } from '@customer-apps/shared/utils';
import { ExternalToolUrlService } from './external-tool-url.service';

@Injectable({
    providedIn: 'root'
})
export class MigrationService {
    constructor(private router: Router, private externalToolUrlService: ExternalToolUrlService) {}

    public hasMigrationsToVerify(project: ProjectResponseGetById) {
        return project.projectStatus === ProjectStatus.Migrated;
    }

    public getQueue(project: ProjectResponseGetById): Queue<MigrationVerification> {
        const migrations = this.getMigrations(project);
        const allMigrationVerifications = new Queue<MigrationVerification>();

        if (this.hasBasicDataMigration(migrations)) {
            const basicDataVerification: MigrationVerification = this.getBasicDataVerification(project.id!);
            allMigrationVerifications.enqueue(basicDataVerification);
        }

        if (this.hasEnergyTarriffsMigration(migrations)) {
            const energyPricesVerification: MigrationVerification = this.getEnergyPricesVerification(project.id!);
            allMigrationVerifications.enqueue(energyPricesVerification);
        }

        if (this.hasHeatPumpPlanningMigration(project)) {
            const heatPumpPlanningVerification: MigrationVerification = this.getHeatPumpVerification(project.id!);
            allMigrationVerifications.enqueue(heatPumpPlanningVerification);
        }

        if (this.hasPhotovoltaicPlannerMigration(project)) {
            const photovoltaicPlanningVerification: MigrationVerification = this.getPhotovoltaicVerification(project.id!);
            allMigrationVerifications.enqueue(photovoltaicPlanningVerification);
        }

        return allMigrationVerifications;
    }

    private hasBasicDataMigration(migrations: MigrationSectionType[] | undefined): boolean {
        return migrations
            ? hasSharedItems(migrations, [
                  MigrationSectionType.BasicData,
                  MigrationSectionType.LocaleData,
                  MigrationSectionType.BuildingAddressData
              ])
            : false;
    }

    private hasEnergyTarriffsMigration(migrations: MigrationSectionType[] | undefined): boolean {
        return !!migrations?.includes(MigrationSectionType.EnergyPricesAndTariffsData);
    }

    private hasHeatPumpPlanningMigration(project: ProjectResponseGetById): boolean {
        const allPlannings = ProjectUtils.getAllPlanningsFromAllVariantsByAppId(project, ApplicationId.HeatPumpPlanner);
        return !!allPlannings?.some(planning => planning?.isMigrated);
    }

    private hasPhotovoltaicPlannerMigration(project: ProjectResponseGetById): boolean {
        const allPlannings = ProjectUtils.getAllPlanningsFromAllVariantsByAppId(project, ApplicationId.PhotovoltaicPlanner);
        return !!allPlannings?.some(planning => planning?.isMigrated);
    }

    private getBasicDataVerification(projectId: string): MigrationVerification {
        return {
            id: MigrationIds.BasicData,
            namePhraseKey: 'SHARED.MIGRATIONS.BASIC_INFORMATION',
            navigateTo: this.navigateToBasicDataVerification.bind(this, projectId),
            prepareTo: this.navigateToBasicDataVerification.bind(this, projectId)
        };
    }

    private getEnergyPricesVerification(projectId: string): MigrationVerification {
        return {
            id: MigrationIds.EnergyPrices,
            namePhraseKey: 'SHARED.MIGRATIONS.ENERGY_PRICES',
            navigateTo: this.navigateToEnergyPricesVerification.bind(this, projectId),
            prepareTo: this.navigateToEnergyPricesVerification.bind(this, projectId)
        };
    }

    private getHeatPumpVerification(projectId: string): MigrationVerification {
        return {
            id: ApplicationId.HeatPumpPlanner,
            namePhraseKey: applicationIdToPhraseKey[ApplicationId.HeatPumpPlanner],
            navigateTo: this.navigateToHeatPumpPlannerVerification.bind(this, projectId),
            prepareTo: this.navigateToProjectDetails.bind(this, projectId)
        };
    }

    private getPhotovoltaicVerification(projectId: string): MigrationVerification {
        return {
            id: ApplicationId.PhotovoltaicPlanner,
            namePhraseKey: applicationIdToPhraseKey[ApplicationId.PhotovoltaicPlanner],
            navigateTo: this.externalToolUrlService.redirectToExternalToolByProjectId.bind(
                this.externalToolUrlService,
                ExternalTool.PhotovoltaicPlanner,
                {
                    projectId,
                    navigateTo: 'system'
                }
            ),
            prepareTo: this.navigateToProjectDetails.bind(this, projectId)
        };
    }

    private navigateToProjectDetails(projectId: string) {
        this.router.navigate([`${RouterLinks.Project}${projectId}`]);
    }

    private navigateToBasicDataVerification(projectId: string) {
        this.router.navigate([`${RouterLinks.Project}${projectId}${RouterLinks.VerifyBasicInformation}`]);
    }

    private navigateToEnergyPricesVerification(projectId: string) {
        this.router.navigate([`${RouterLinks.Project}${projectId}${RouterLinks.VerifyPrices}`]);
    }

    private navigateToHeatPumpPlannerVerification(projectId: string) {
        this.router.navigate([`${RouterLinks.Project}${projectId}/plan-heat-pump`], {
            queryParams: { migration: 'true', reconfigure: 'true' }
        });
    }

    private getMigrations(project: ProjectResponseGetById): MigrationSectionType[] | undefined {
        return project.projectMigration?.migrationSectionsToVerify as MigrationSectionType[] | undefined;
    }
}
