import { Injectable } from '@angular/core';
import { ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import { CountryCode, CountryLanguageCodes, EnergyStandard, RemoteConfigParameter } from '@customer-apps/shared/enums';
import { EnumDictionary, LocaleUtils, isOfCountry } from '@customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { RemoteConfigService } from '../services';
import { AuthStore } from '../state/auth/auth.store';
import { Observable, map, withLatestFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RollOutPolicy {
    constructor(private remoteConfig: RemoteConfigService, private translate: TranslateService, private authStore: AuthStore) {}

    /**
     * Filters for remotely enabled country codes to build options for country-language switcher.
     */
    public getCountryLanguagePairs$(): Observable<
        {
            key: string;
            locale: CountryLanguageCodes;
        }[]
    > {
        return this.authStore.countryLanguagePairs$.pipe(
            withLatestFrom(this.remoteConfig.state$, (pairs, remoteConfig) => ({ pairs, remoteConfig })),
            map(({ pairs, remoteConfig }) => {
                const supportedPairs = pairs.filter(pair => {
                    const countryCode = LocaleUtils.getCountryCode(pair.locale);
                    return remoteConfig.config[RemoteConfigParameter.CountryLanguageSwitcherByCountry].includes(countryCode);
                });
                return supportedPairs;
            })
        );
    }

    /**
     * Filters for remotely enabled country codes to build options for country dropdown.
     */
    public getOptionsForCountryDropdown(countries: CountryCode[]): CountryCode[] {
        const supportedCountries = countries.filter(country => {
            return this.remoteConfig.config.countryDropdownByCountry.includes(country);
        });
        return supportedCountries as CountryCode[];
    }

    /**
     * Determines whether the Heat Pump Planner features should be enabled/displayed in the scope of specific countries.
     */
    public hasHeatPumpPlanning(addressCountryCode: CountryCode): boolean {
        return isOfCountry(addressCountryCode, [
            CountryCode.DE,
            CountryCode.AT,
            CountryCode.BE,
            CountryCode.NL,
            CountryCode.FR,
            CountryCode.CH
        ]);
    }

    /**
     * Determines whether the Photovoltaic Planner features should be enabled/displayed in the scope of specific countries.
     */
    public hasPhotovoltaicPlanning(addressCountryCode: CountryCode): boolean {
        return isOfCountry(addressCountryCode, [CountryCode.DE, CountryCode.AT, CountryCode.BE, CountryCode.NL, CountryCode.FR]);
    }

    /**
     * Determines whether the lock period tarriff should be enabled/displayed in the scope of specific countries.
     */
    public hasLockPeriodTarriff(addressCountryCode: CountryCode) {
        return !isOfCountry(addressCountryCode, CountryCode.BE);
    }

    /**
     * Determines whether a living area calculation is enabled/displayed in the scope of specific countries.
     */
    public hasLivingAreaCalcuatiion(addressCountryCode: CountryCode) {
        return !isOfCountry(addressCountryCode, CountryCode.BE);
    }

    /**
     * Determines whether a building hull calculation is enabled/displayed in the scope of specific countries.
     */
    public hasBuildingHullCalculation(addressCountryCode: CountryCode) {
        return !isOfCountry(addressCountryCode, CountryCode.BE);
    }

    /**
     * Determines a deafult air exchange rate in the scope of specific countries.
     */
    public getDefaultAirExchangeRate(addressCountryCode: CountryCode): number | undefined {
        const rates: Partial<EnumDictionary<CountryCode, number>> = {
            [CountryCode.BE]: 0.24
        };
        return rates[addressCountryCode];
    }

    /**
     * Determines a deafult energy standard in the scope of specific countries.
     */
    public getDefaultEnergyStandard(addressCountryCode: CountryCode): EnergyStandard | undefined {
        const rates: Partial<EnumDictionary<CountryCode, EnergyStandard>> = {
            [CountryCode.BE]: EnergyStandard.None
        };
        return rates[addressCountryCode];
    }

    /**
     * Determines a deafult energy standard message in the scope of specific countries.
     */
    public getDefaultEnergyStandardSuccessMessage(addressCountryCode: CountryCode): string | undefined {
        const rates: Partial<EnumDictionary<CountryCode, string>> = {
            [CountryCode.BE]: this.translate.instant('HEATLOAD.ENERGY_STANDARD.ENERGY_STANDARDS.45WM2')
        };
        return rates[addressCountryCode];
    }
}
