/**
 * Defines a set of all energy standards for buildings.
 */
export enum EnergyStandard {
    EnEv2016 = 'ENEV2016',
    KfW55 = 'KFW55',
    KfW40 = 'KFW40',
    KfW40Plus = 'KFW40PLUS',
    None = 'NONE'
}
