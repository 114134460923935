import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { EnvironmentHeadersInterceptor } from './interceptors/environment-headers.interceptor';
import { EnvironmentService } from './services';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { heatloadReducer } from './state/heatload/heatload.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from 'apps/viguide-planning/src/environments/environment';
import { authReducer } from 'apps/viguide-planning/src/app/state/auth/auth.reducer';
import { AuthEffects } from 'apps/viguide-planning/src/app/state/auth/auth.effects';
import { ApiInterceptor } from 'apps/viguide-planning/src/app/interceptors/api.interceptor';
import { ErrorsInterceptor } from 'apps/viguide-planning/src/app/interceptors/errors.interceptor';
import { IdentityInterceptor } from 'apps/viguide-planning/src/app/interceptors/identity.interceptor';
import { ProjectEffects } from './state/project/project.effects';
import { projectReducer } from './state/project/project.reducer';
import { ProjectsEffects } from './state/projects/projects.effects';
import { projectsReducer } from './state/projects/projects.reducer';
import { registerLocaleData } from '@angular/common';
import { HeatloadEffects } from './state/heatload/heatload.effects';
import { heatPumpIntermediaryReducer } from './state/heat-pump-intermediary/heat-pump-intermediary.reducer';
import { HeatPumpIntermediaryEffects } from './state/heat-pump-intermediary/heat-pump-intermediary.effects';
import { settingsReducer } from './state/settings/settings.reducer';
import { SettingsEffects } from './state/settings/settings.effects';
import { LocaleProvider } from './i18n/locale.provider';
import { footerReducer } from './state/footer/footer.reducer';
import { FooterEffects } from './state/footer/footer.effects';
import { ApiModule } from '@customer-apps/api-planning-projects';
import { appSpecificDataReducer } from './state/app-specific-data/app-specific-data.reducer';
import { AppSpecificDataEffects } from './state/app-specific-data/app-specific-data.effects';
import { ResponseSummaryActionInterceptor } from './interceptors/response-summary-action.interceptor';
import { GEGReducer } from './state/geg/geg.reducer';
import { GEGEffects } from './state/geg/geg.effects';
import { GEGEvaluationReducer } from './state/geg-evaluation/geg-evaluation.reducer';
import { GEGEvaluationEffects } from './state/geg-evaluation/geg-evaluation.effects';
import { localStorageSyncReducer } from './state/helpers/local-storage.helper';

// locales
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnDe from '@angular/common/locales/en-DE';
import localeEnDeExtra from '@angular/common/locales/extra/en-DE';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlBe from '@angular/common/locales/nl-BE';
import localeNlBeExtra from '@angular/common/locales/extra/nl-BE';
import localeDeAt from '@angular/common/locales/de-AT';
import localeDeAtExtra from '@angular/common/locales/extra/de-AT';
import localeDeCH from '@angular/common/locales/de-CH';
import localeDeCHExtra from '@angular/common/locales/extra/de-CH';
import localefrCH from '@angular/common/locales/fr-CH';
import localefrCHExtra from '@angular/common/locales/extra/fr-CH';
import localefrFr from '@angular/common/locales/fr';
import localefrFRExtra from '@angular/common/locales/extra/fr';
import localeplPl from '@angular/common/locales/pl';
import localeplPLExtra from '@angular/common/locales/extra/pl';
import localecsCZ from '@angular/common/locales/cs';
import localecsCZExtra from '@angular/common/locales/extra/cs';
import localeskSK from '@angular/common/locales/sk';
import localeskSKExtra from '@angular/common/locales/extra/sk';
import localeesES from '@angular/common/locales/es';
import localeesESExtra from '@angular/common/locales/extra/es';
import { ProjectAccessReducer } from './state/project-access/project-access.reducer';
import { ProjectAccessEffects } from './state/project-access/project-access.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './i18n/http-loader';
import { LocaleUtils } from '@customer-apps/shared/utils';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeEnDe, 'en-DE', localeEnDeExtra);
registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);
registerLocaleData(localeNlBe, 'nl-BE', localeNlBeExtra);
registerLocaleData(localeDeAt, 'de-AT', localeDeAtExtra);
registerLocaleData(localeDeCH, 'de-CH', localeDeCHExtra);
registerLocaleData(localefrCH, 'fr-CH', localefrCHExtra);
registerLocaleData(localefrFr, 'fr-FR', localefrFRExtra);
registerLocaleData(localeplPl, 'pl-PL', localeplPLExtra);
registerLocaleData(localecsCZ, 'cs-CZ', localecsCZExtra);
registerLocaleData(localeskSK, 'sk-SK', localeskSKExtra);
registerLocaleData(localeesES, 'es-ES', localeesESExtra);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        CoreModule,
        ApiModule.forRoot({ rootUrl: environment.baseApiUrl }),
        /* Order of imported modules must be as follows:
         * GlobalHeaderModule -> StoreModule -> EffectsModule -> StoreDevtoolsModule
         * */
        GlobalHeaderModule.forRoot({
            baseUrl: environment.everestApiUrl,
            accountAppUrl: environment.accountApiUrl,
            appId: environment.csrfTokenConfig.appId,
            logoutRedirectUrl: environment.registerRedirectUrl,
            iamBackgroundImage: environment.globalHeader.iamBackgroundImage,
            partnerPortalBaseUrl: environment.partnerPortalBaseUrl
        }),
        StoreModule.forRoot(
            {
                auth: authReducer,
                heatload: heatloadReducer,
                project: projectReducer,
                projects: projectsReducer,
                projectAccess: ProjectAccessReducer,
                heatPumpIntermediary: heatPumpIntermediaryReducer,
                settings: settingsReducer,
                footer: footerReducer,
                appSpecificData: appSpecificDataReducer,
                GEG: GEGReducer,
                GEGEvaluation: GEGEvaluationReducer
            },
            {
                metaReducers: [localStorageSyncReducer]
            }
        ),
        EffectsModule.forRoot([
            AuthEffects,
            ProjectEffects,
            ProjectsEffects,
            HeatloadEffects,
            HeatPumpIntermediaryEffects,
            SettingsEffects,
            FooterEffects,
            GEGEffects,
            GEGEvaluationEffects,
            AppSpecificDataEffects,
            ProjectAccessEffects
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            autoPause: true,
            connectInZone: true
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.serviceWorker
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            },
            defaultLanguage: LocaleUtils.defaultBrowserLocale
        })
    ],
    providers: [
        EnvironmentService,
        LocaleProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: IdentityInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EnvironmentHeadersInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseSummaryActionInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
