export enum ReportConfigurationSection {
    GeneralInformation = 'GENERAL_INFORMATION',
    HeatPumpPlan = 'HEAT_PUMP_PLAN',
    PVPlan = 'PV_PLAN',
    VentilationPlan = 'VENTILATION_PLAN',
    FullSystem = 'FULL_SYSTEM',
    LegalNotice = 'LEGAL_NOTICE',
    GeneralProjectData = 'GENERAL_PROJECT_DATA',
    GEGRecommendations = 'GEG_RECOMMENDATIONS',
    CurrentSystem = 'CURRENT_SYSTEM',
    PlannedSystem = 'PLANNED_SYSTEM',
    HeatLoadCalculations = 'HEAT_LOAD_CALCULATIONS',
    FinancialPerformance = 'FINANCIAL_PERFORMANCE',
    HPFinancialPerformance = 'HP_FINANCIAL_PERFORMANCE',
    PVFinancialPerformance = 'PV_FINANCIAL_PERFORMANCE',
    HPSimulationResults = 'HP_SIMULATION_RESULTS',
    PVSimulationResults = 'PV_SIMULATION_RESULTS',
    FullSystemSimulation = 'FULL_SYSTEM_SIMULATION',
    Roofs = 'ROOFS',
    PVTechnicalDetails = 'PV_TECHNICAL_DETAILS',
    PVInstallationPlans = 'PV_INSTALLATION_PLANS',
    BillOfMaterials = 'BILL_OF_MATERIALS',
    HPBillOfMaterials = 'HP_BILL_OF_MATERIALS',
    PVBillOfMaterials = 'PV_BILL_OF_MATERIALS',
    PVStaticsAnalysisReport = 'PV_STATICS_ANALYSIS_REPORT'
}
