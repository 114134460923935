import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, switchMap, withLatestFrom, of, iif } from 'rxjs';
import { HeatloadService } from '../../heatload/services/heatload.service';
import { BuildingType, EnergySource, HeatGeneratorType, HeatLoadCalculationMethod, ProjectType } from '@customer-apps/shared/enums';
import { ProjectStore } from '../project/project.store';
import { HeatPumpIntermediaryActions } from './heat-pump-intermediary.actions';
import { ProjectsService } from '../../services';
import { HeatPumpIntermediaryStore } from './heat-pump-intermediary.store';

@Injectable()
export class HeatPumpIntermediaryEffects {
    constructor(
        private actions$: Actions,
        private heatloadService: HeatloadService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore
    ) {}

    public onBuildingSubmit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpIntermediaryActions.submitBuilding),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ project }) => {
                return iif(
                    () =>
                        this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                        this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.CustomValue),
                    of(HeatPumpIntermediaryActions.getHeatGenerators()),
                    EMPTY
                );
            })
        )
    );

    public onDeviceSubmit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpIntermediaryActions.submitDevice),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ project }) => {
                return iif(
                    () =>
                        this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                        (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.LivingSpace) ||
                            this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.BuildingHull)),
                    of(HeatPumpIntermediaryActions.getHeatGenerators()),
                    EMPTY
                );
            })
        )
    );

    public getHeatGenerators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpIntermediaryActions.getHeatGenerators),
            switchMap(({ selectedEnergySourceType, selectedHeatGeneratorType }) =>
                this.heatloadService.getHeatGenerators().pipe(
                    map(heatGenerators =>
                        HeatPumpIntermediaryActions.getHeatGeneratorsSuccess({
                            heatGenerators,
                            selectedEnergySourceType,
                            selectedHeatGeneratorType
                        })
                    ),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    public onEnergyConsumptionSubmit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpIntermediaryActions.submitEnergyConsumption),
            map(() => HeatPumpIntermediaryActions.getConsumptionProfiles())
        )
    );

    public getConsumptionProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpIntermediaryActions.getConsumptionProfiles),
            withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
            switchMap(({ project }) =>
                this.heatloadService
                    .getConsumptionProfiles(project!.projectType as ProjectType, project!.building.buildingType as BuildingType)
                    .pipe(map(consumptionProfiles => HeatPumpIntermediaryActions.getConsumptionProfilesSuccess({ consumptionProfiles })))
            )
        )
    );

    public getHeatingSystems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpIntermediaryActions.getHeatingSystems),
            withLatestFrom(this.heatPumpIntermediaryStore.selectedHeatingEnergySource$, (action, energySourceState) => ({
                action,
                energySourceState
            })),
            switchMap(({ action, energySourceState }) =>
                this.heatloadService
                    .getHeatingSystems(
                        energySourceState!.selectedEnergySource!.type as EnergySource,
                        +action.installationYear,
                        energySourceState!.selectedHeatGenerator as HeatGeneratorType
                    )
                    .pipe(catchError(error => EMPTY))
            ),
            map(heatingSystems => HeatPumpIntermediaryActions.getHeatingSystemsSuccess({ heatingSystems }))
        )
    );
}
