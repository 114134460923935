import { createReducer, on } from '@ngrx/store';
import { HeatloadState, InsulationFormValue, ModernizationsAndInsulationFormValue } from '@customer-apps/shared/interfaces';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { HeatloadActions } from './heatload.actions';
import { percentToFraction } from '@customer-apps/shared/utils';

export const initialState: HeatloadState = {};

export const heatloadReducer = createReducer(
    initialState,
    on(HeatloadActions.submitCalculationMethod, (state, formValue) => {
        return formValue.methods.standardMethod
            ? {
                  ...initialState,
                  definedValue: undefined,
                  calculationMethod: { value: formValue.methods.standardMethod }
              }
            : { ...initialState, calculationMethod: undefined, definedValue: formValue.methods.definedValue };
    }),
    on(HeatloadActions.submitLivingArea, (state, formValue) => {
        return {
            ...state,
            energyStandards: undefined,
            modernization: undefined,
            ventilation: undefined,
            livingArea: {
                value: formValue.livingArea
            }
        };
    }),
    on(HeatloadActions.submitEnergyCertificate, (state, formValue) => {
        return {
            ...state,
            energyCertificate: {
                surroundingArea: formValue.surroundingArea,
                buildingVolume: formValue.buildingVolume,
                airExchangeRate: formValue.airExchangeRate,
                specificTransmissionHeatLoss: formValue.specificTransmissionHeatLoss
            }
        };
    }),
    on(HeatloadActions.submitBuildingHull, (state, formValue) => {
        return {
            ...state,
            windows: undefined,
            modernizationsAndInsulation: undefined,
            roofType: undefined,
            roofDimensions: undefined,
            windowsAndGlazing: undefined,
            buildingHull: {
                buildingDimensions: formValue.buildingDimensions,
                buildingPosition: formValue.buildingPosition,
                ventilationAndHeating: formValue.ventilationAndHeating
            }
        };
    }),
    on(HeatloadActions.submitHeatGenerator, (state, formValue) => {
        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            heatGeneratorDetails: undefined,
            heatingEnergySource: {
                selectedEnergySource: getSelectedSourceFromGenerators(
                    state.heatGenerators!,
                    formValue.heatGenerator,
                    formValue.specificEnergySource
                ),
                selectedHeatGenerator: formValue.heatGenerator
            }
        };
    }),
    on(HeatloadActions.submitModernization, (state, formValue) => {
        return {
            ...state,
            ventilation: undefined,
            modernization: {
                buildingConstructionYear: formValue.buildingConstructionYear,
                roofModernization: formValue.roofModernization,
                wallsModernization: formValue.wallsModernization,
                windowsModernization: formValue.windowsModernization
            }
        };
    }),
    on(HeatloadActions.submitRoofType, (state, formValue) => {
        return {
            ...state,
            roofDimensions: undefined,
            windows: undefined,
            modernizationsAndInsulation: undefined,
            windowsAndGlazing: undefined,
            roofType: { value: formValue.roofType }
        };
    }),
    on(HeatloadActions.submitWindowsAndGlazing, (state, formValue) => {
        return {
            ...state,
            windowsAndGlazing: {
                typeOfGlazing: formValue.typeOfGlazing,
                windowsPercentageArea: formValue.windowsPercentageArea
            }
        };
    }),
    on(HeatloadActions.submitHeatGeneratorDetails, (state, formValue) => {
        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            heatGeneratorDetails: {
                boilerType: formValue.boilerType,
                heatPumpOperatingMode: formValue?.heatPumpOperatingMode,
                lowTemperature: formValue.boilerTypeSubquestion?.lowTemperature,
                warmWaterByHeatGenerator: formValue.boilerTypeSubquestion?.warmWaterByHeatGenerator,
                gasLossExhaust: percentToFraction(formValue.gasLossExhaust),
                gasLossExhaustKnown: formValue.gasLossExhaustKnown?.isKnown,
                installationYear: formValue.installationYear,
                nominalPower: formValue.nominalPower
            },
            selectedHeatingSystem: state.heatingSystems!.find(system => system.type === formValue.boilerType)
        };
    }),
    on(HeatloadActions.submitModernizationsAndInsulation, (state, formValue) => {
        if ((<ModernizationsAndInsulationFormValue>formValue).buildingConstructionYear) {
            return {
                ...state,
                windowsAndGlazing: undefined,
                modernizationsAndInsulation: {
                    buildingConstructionYear: (<ModernizationsAndInsulationFormValue>formValue).buildingConstructionYear,
                    windowsConstructionYear: (<ModernizationsAndInsulationFormValue>formValue).windowsConstructionYear,
                    roofRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).roofRenovationAndInsulation,
                    upperFloorRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).upperFloorRenovationAndInsulation,
                    wallsRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).wallsRenovationAndInsulation,
                    basementRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).basementRenovationAndInsulation
                }
            };
        }

        return {
            ...state,
            windowsAndGlazing: undefined,
            modernizationsAndInsulation: {
                roofInsulation: (<InsulationFormValue>formValue).roofInsulation,
                atticInsulation: (<InsulationFormValue>formValue).atticInsulation,
                wallsInsulation: (<InsulationFormValue>formValue).wallsInsulation,
                cellarInsulation: (<InsulationFormValue>formValue).cellarInsulation
            }
        };
    }),
    on(HeatloadActions.submitEnergyStandard, (state, formValue) => {
        return {
            ...state,
            ventilation: undefined,
            energyStandards: { value: formValue.energyStandards }
        };
    }),
    on(HeatloadActions.submitVentilation, (state, formValue) => {
        return {
            ...state,
            ventilation: { value: formValue.ventilation }
        };
    }),
    on(HeatloadActions.submitRoofDimensions, (state, formValue) => {
        return {
            ...state,
            windowsAndGlazing: undefined,
            modernizationsAndInsulation: undefined,
            windows: undefined,
            roofDimensions: formValue.roofDimensions
        };
    }),

    on(HeatloadActions.submitEnergyConsumption, (state, formValue) => {
        return {
            ...state,
            heatingDistribution: undefined,
            energyConsumption: {
                requiredEnergyPerYear: formValue.requiredEnergyPerYear,
                nonHeatingElectricityPerYear: formValue.nonHeatingElectricityPerYear
            }
        };
    }),
    on(HeatloadActions.submitHeatingDistribution, (state, formValue) => {
        return {
            ...state,
            heatingDistribution: {
                distributionMethod: formValue.distributionMethod,
                heatingFlowTemperature: formValue.heatingFlowTemperature,
                heatingSchedule: formValue.heatingSchedule
            }
        };
    }),
    on(HeatloadActions.getHeatGeneratorsSuccess, (state, action) => {
        return { ...state, heatGenerators: [...action.generators] };
    }),
    on(HeatloadActions.getWindowsSuccess, (state, action) => {
        return { ...state, windows: [...action.windows] };
    }),
    on(HeatloadActions.getHeatingSystemsSuccess, (state, action) => {
        return { ...state, heatingSystems: action.heatingSystems };
    }),
    on(HeatloadActions.submitSameValue, (state, action) => {
        const { formName, value } = action;
        return { ...state, sameValueSubmited: { formName, value } };
    }),
    on(HeatloadActions.resetSameValue, state => {
        return { ...state, sameValueSubmited: undefined };
    }),
    on(HeatloadActions.resetHeatload, () => initialState)
);
