<div class="d-flex justify-content-between pt-4 pb-0" mat-dialog-title data-testid="confirm-dialog-title">
    <h3 *ngIf="data.title">
        {{ data.title }}
    </h3>
    <h3 *ngIf="data.titleElement">
        <ng-container *ngTemplateOutlet="data.titleElement; context: { $implicit: data.context }"></ng-container>
    </h3>
    <button
        mat-icon-button
        class="vp-button close-button ms-auto d-flex justify-content-center"
        data-testid="confirm-dialog-close-btn"
        (click)="onClose()"
    >
        <vp-icon icon="cross"></vp-icon>
    </button>
</div>
<mat-dialog-content *ngIf="data.content" class="vp-pre-line pb-0">
    {{ data.content }}
</mat-dialog-content>
<mat-dialog-content *ngIf="data.contentElement" class="pb-0">
    <ng-container *ngTemplateOutlet="data.contentElement; context: { $implicit: data.context }"></ng-container>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.formElement" class="pb-0">
    <form [formGroup]="form">
        <ng-container *ngTemplateOutlet="data.formElement; context: { $implicit: form }"></ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end align-items-center vp-gap-8 py-3 px-4">
    <button
        mat-stroked-button
        color="primary"
        class="vp-button vp-button-m"
        (click)="onCancel()"
        data-testid="confirm-dialog-cancel-btn"
        id="pop-up-cancel"
    >
        {{ data.cancelBtn }}
    </button>
    <button
        mat-flat-button
        class="vp-button vp-button-m"
        color="primary"
        [disabled]="data.formElement && form.invalid"
        (click)="onConfirm()"
        data-testid="confirm-dialog-confirm-btn"
        id="pop-up-confirm"
    >
        {{ data.confirmBtn }}
    </button>
</mat-dialog-actions>
