import { Pipe, PipeTransform } from '@angular/core';
import { AnyProduct, CylinderProduct, HeatGeneratorProduct } from '@customer-apps/api-planning-projects';
import { ProductType, TechnicalDataKeys } from '@customer-apps/shared/enums';
import { TachnicalData } from '@customer-apps/shared/interfaces';
import { fractionToPercent, multiplyBy, technicalDataKeysToPhraseKey, technicalDataKeysToUnit } from '@customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'productTechnicalData'
})
export class ProductTechnicalDataPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    public transform(product: AnyProduct | CylinderProduct | HeatGeneratorProduct | undefined): TachnicalData[] | undefined {
        if (!product) {
            return;
        }

        switch (product.productType) {
            case ProductType.PhotovoltaicAllInOneSystem:
                return this.getPhotovoltaicAllInOneSystem(product);
            case ProductType.PhotovoltaicBatteryInverter:
                return this.getPhotovoltaicBatteryInverterTechnicalData(product);
            case ProductType.PhotovoltaicBatteryModule:
                return this.getPhotovoltaicBatteryModuleTechnicalData(product);
            case ProductType.PhotovoltaicHybridInverter:
                return this.getPhotovoltaicHybridInverterTechnicalData(product);
            case ProductType.PhotovoltaicInverter:
                return this.getPhotovoltaicInverterTechnicalData(product);
            case ProductType.PhotovoltaicPanelModule:
                return this.getPhotovoltaicPanelModuleTechnicalData(product);
            case ProductType.PhotovoltaicModuleInverter:
            case ProductType.PowerInverter:
                return this.getPhotovoltaicModuleInverterTechnicalData(product);
            case ProductType.PhotovoltaicBattery:
                return this.getPhotovoltaicBatteryTechnicalData(product);
            case ProductType.BatteryStorage:
            case ProductType.HybridBatteryStorage:
                return this.getBatteryStorageTechnicalData(product);
            case ProductType.Photovoltaic:
                return this.getPhotovoltaicTechnicalData(product);
            case ProductType.PhotovoltaicModule:
                return this.getPhotovoltaicModuleTechnicalData(product);
            default:
                return undefined;
        }
    }

    private getPhotovoltaicModuleInverterTechnicalData(product: any) {
        return [this.getMaxDCPowerIn(product), this.getMaxACPowerOut(product), this.getMaxEfficiency(product)];
    }

    private getPhotovoltaicBatteryTechnicalData(product: any) {
        return [this.getBatteryCapacity(product), this.getMaxDischargingPower(product)];
    }

    private getBatteryStorageTechnicalData(product: any) {
        return [this.getBatteryCapacity(product), this.getMaxChargingPower(product), this.getMaxDischargingPower(product)];
    }

    private getPhotovoltaicTechnicalData(product: any) {
        return [this.getModuleNominalPower(product), this.getMaxEfficiency(product), this.getDimensions(product)];
    }

    private getPhotovoltaicModuleTechnicalData(product: any) {
        return [this.getModuleNominalPower(product), this.getMaxModuleEfficiency(product), this.getDimensions(product)];
    }

    private getPhotovoltaicPanelModuleTechnicalData(product: any) {
        return [this.getModuleNominalPower(product), this.getMaxEfficiency(product), this.getDimensions(product)];
    }

    private getPhotovoltaicInverterTechnicalData(product: any) {
        return [this.getMaxDCPowerIn(product), this.getMaxACPowerOut(product), this.getMaxEfficiency(product)];
    }

    private getPhotovoltaicHybridInverterTechnicalData(product: any) {
        return [
            this.getMaxDCPowerIn(product),
            this.getMaxACPowerOut(product),
            this.getMaxChargingPower(product),
            this.getMaxEfficiency(product)
        ];
    }

    private getPhotovoltaicBatteryModuleTechnicalData(product: any) {
        return [this.getBatteryCapacity(product), this.getMaxChargingPower(product), this.getMaxDischargingPower(product)];
    }

    private getPhotovoltaicBatteryInverterTechnicalData(product: any) {
        return [this.getMaxDischargingPower(product), this.getMaxEfficiency(product)];
    }

    private getPhotovoltaicAllInOneSystem(product: any) {
        return [
            this.getMaxDCPowerIn(product),
            this.getMaxACPowerOut(product),
            this.getBatteryCapacity(product),
            this.getMaxEfficiency(product)
        ];
    }

    private getBatteryCapacity(product: any) {
        return {
            key: TechnicalDataKeys.BatteryCapacity,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.BatteryCapacity]!,
            value: product.technicalData[TechnicalDataKeys.BatteryCapacity],
            unit: technicalDataKeysToUnit[TechnicalDataKeys.BatteryCapacity]!
        };
    }

    private getDimensions(product: any) {
        const dimensionsUnit = this.translateService.instant(technicalDataKeysToUnit[TechnicalDataKeys.Dimensions] as string);
        return {
            key: TechnicalDataKeys.Dimensions,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.Dimensions]!,
            values: [
                { value: product.technicalData[TechnicalDataKeys.Length], unit: dimensionsUnit },
                { value: product.technicalData[TechnicalDataKeys.Width], unit: dimensionsUnit },
                { value: product.technicalData[TechnicalDataKeys.Height], unit: dimensionsUnit }
            ]
        };
    }

    private getMaxACPowerOut(product: any) {
        return {
            key: TechnicalDataKeys.MaxACPowerOut,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.MaxACPowerOut]!,
            value: product.technicalData[TechnicalDataKeys.MaxACPowerOut],
            unit: technicalDataKeysToUnit[TechnicalDataKeys.MaxACPowerOut]!
        };
    }

    private getMaxChargingPower(product: any) {
        return {
            key: TechnicalDataKeys.MaxChargingPower,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.MaxChargingPower]!,
            value: product.technicalData[TechnicalDataKeys.MaxChargingPower],
            unit: technicalDataKeysToUnit[TechnicalDataKeys.MaxChargingPower]!
        };
    }

    private getMaxDCPowerIn(product: any) {
        return {
            key: TechnicalDataKeys.MaxDCPowerIn,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.MaxDCPowerIn]!,
            value: product.technicalData[TechnicalDataKeys.MaxDCPowerIn],
            unit: technicalDataKeysToUnit[TechnicalDataKeys.MaxDCPowerIn]!
        };
    }

    private getMaxDischargingPower(product: any) {
        return {
            key: TechnicalDataKeys.MaxDischargingPower,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.MaxDischargingPower]!,
            value: product.technicalData[TechnicalDataKeys.MaxDischargingPower],
            unit: technicalDataKeysToUnit[TechnicalDataKeys.MaxDischargingPower]!
        };
    }

    private getMaxEfficiency(product: any) {
        return {
            key: TechnicalDataKeys.MaxEfficiency,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.MaxEfficiency]!,
            value: fractionToPercent(product.technicalData[TechnicalDataKeys.MaxEfficiency]),
            unit: technicalDataKeysToUnit[TechnicalDataKeys.MaxEfficiency]!
        };
    }

    private getMaxModuleEfficiency(product: any) {
        return {
            key: TechnicalDataKeys.MaxModuleEfficiency,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.MaxModuleEfficiency]!,
            value: fractionToPercent(product.technicalData[TechnicalDataKeys.MaxModuleEfficiency]),
            unit: technicalDataKeysToUnit[TechnicalDataKeys.MaxModuleEfficiency]!
        };
    }

    private getModuleNominalPower(product: any) {
        return {
            key: TechnicalDataKeys.ModuleNominalPower,
            name: technicalDataKeysToPhraseKey[TechnicalDataKeys.ModuleNominalPower]!,
            value: multiplyBy(product.technicalData[TechnicalDataKeys.ModuleNominalPower], 1000),
            unit: technicalDataKeysToUnit[TechnicalDataKeys.ModuleNominalPower]!
        };
    }
}
