import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserResult } from '@customer-apps/shared/interfaces';
import { AuthStore } from 'apps/viguide-planning/src/app/state/auth/auth.store';
import { EnvironmentService } from '../services';
import { WindowService } from '@customer-apps/shared/services';
import { LocaleService } from '../services/locale.service';

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {
    private userData: UserResult;

    constructor(
        private envService: EnvironmentService,
        private localeService: LocaleService,
        private windowService: WindowService,
        private authStore: AuthStore
    ) {
        this.authStore.me$.subscribe((userResult: UserResult) => (this.userData = userResult));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // use cookies only for endpoint .../saml/csrf
        const withCredentials = request.url.endsWith('/saml/csrf');

        request = request.clone({
            setHeaders: this.getHeaders(request),
            withCredentials
        });
        return next.handle(request);
    }

    private getHeaders(request: HttpRequest<any>): any {
        const headers: { [key: string]: string } = {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': this.localeService.getLocaleId() || 'de',
            'X-CA-APPLICATION-LANG-CODE': this.localeService.getLocaleId() || 'de',
            'X-CA-APPLICATION-VERSION': this.envService.version
        };

        const token: string | null = this.getCsrfToken();
        if (token) {
            headers['Authorization'] = `CSRF ${token}`;
        }

        if (this.isAdminEndpointCall(request)) {
            headers['X-CA-USER-ID'] = this.userData.id;
            headers['X-CA-USER-LOGIN'] = this.userData.loginId;
        }

        return headers;
    }

    private isAdminEndpointCall(request: HttpRequest<any>): boolean {
        return request.url.toLowerCase().indexOf('/admin/') > 0;
    }

    private getCsrfToken(): string | null {
        if (this.envService.csrfTokenLocalStorageKey) {
            return this.windowService.localStorage.getItem(this.envService.csrfTokenLocalStorageKey);
        }
        return null;
    }
}
