export enum RouterLinks {
    Project = '/project/',
    Edit = '/edit',
    VerifyBasicInformation = '/verify-basic-information',
    VerifyPrices = '/verify-prices',
    EditPrices = '/edit-prices',
    CreateReport = '/create-report',
    Heatload = '/heatload',
    HeatPump = '/plan-heat-pump',
    Photovoltaic = '/plan-photovoltaic',
    GEG = 'geg'
}
