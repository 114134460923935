import { ControlTypes } from '@customer-apps/shared/enums';
import { QuestionOptions } from '@customer-apps/shared/interfaces';
import { QuestionBase } from './question-base.model';

export class RadioQuestion extends QuestionBase<string> {
    override controlType = ControlTypes.Radio;
    public options: {
        key: string;
        value: string | boolean | number;
        icon?: string;
        img?: string;
        subtext?: string;
        disabled?: boolean;
        checked?: boolean;
    }[];
    constructor(questionOptions: QuestionOptions<string>) {
        super(questionOptions);
        this.options = questionOptions.options || [];
        const selectedOption = this.options.find(option => option.value === questionOptions.value);
        if (selectedOption) {
            selectedOption.checked = true;
        }

        const hasIconAndImageSpecified = this.options.some(option => option.icon && option.img);
        if (hasIconAndImageSpecified) {
            throw Error('Cannot specify image and icon in the same option object');
        }
        const hasSubtextAndImageSpecified = this.options.some(option => option.subtext && option.img);
        if (hasSubtextAndImageSpecified) {
            throw Error('Cannot specify image and a subtext in the same option object');
        }
    }
}
