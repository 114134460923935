import { Planning, ProjectResponseGetById, Variant } from '@customer-apps/api-planning-projects';
import { ApplicationId } from '@customer-apps/shared/enums';

export class ProjectUtils {
    static getVariant(project: ProjectResponseGetById): Variant | undefined {
        return project?.variants?.find(variant => variant.isSelected);
    }

    static getAllPlanningsFromAllVariantsByAppId(project: ProjectResponseGetById, applicationId: ApplicationId): (Planning | undefined)[] {
        const allPlanningFromAllVariants = project.variants
            .map(variant => variant.plannings)
            .filter(plannings => plannings?.length)
            .flat(1);

        const variantsByAppId = allPlanningFromAllVariants.filter(planning => planning?.applicationId === applicationId);

        return variantsByAppId;
    }

    static getPlanningsWithProducts(project: ProjectResponseGetById): Planning[] | undefined {
        const selectedVariant = ProjectUtils.getVariant(project);
        return selectedVariant?.plannings?.filter(planning => planning?.products?.length);
    }

    static getPlanning(project: ProjectResponseGetById, applicationId: ApplicationId): Planning | undefined {
        return ProjectUtils.getPlanningsWithProducts(project)?.find(planning => planning.applicationId === applicationId);
    }

    static getPlanningById(project: ProjectResponseGetById, planningId: string): Planning | undefined {
        return ProjectUtils.getPlanningsWithProducts(project)?.find(planning => planning.id === planningId);
    }

    static hasPlanningsWithProducts(project: ProjectResponseGetById): boolean {
        const plannings = ProjectUtils.getPlanningsWithProducts(project);
        return plannings ? plannings.length > 0 : false;
    }
}
