import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, map, pairwise, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LocationsControllerService } from '@customer-apps/api-planning-projects';
import { LocaleUtils, supportedCountryLanguages } from '@customer-apps/shared/utils';
import { SnackbarService, WindowService } from '@customer-apps/shared/services';
import { CountryCode, RoutePaths } from '@customer-apps/shared/enums';
import { UserResult } from '@customer-apps/shared/interfaces';
import { LocaleService } from '../../services/locale.service';
import { AuthService, EnvironmentService } from '../../services';
import { SettingsActions } from './settings.actions';
import { AuthStore } from '../auth/auth.store';
import { AuthActions } from '../auth/auth.actions';
import { SettingsStore } from './settings.store';

@Injectable()
export class SettingsEffects {
    constructor(
        private actions$: Actions,
        private translateService: TranslateService,
        private localeService: LocaleService,
        private windowService: WindowService,
        private locationsControllerService: LocationsControllerService,
        private snackbarService: SnackbarService,
        private environmentService: EnvironmentService,
        private authStore: AuthStore,
        private settingsStore: SettingsStore,
        private authService: AuthService
    ) {}

    initDefaultLocale$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.initDefaultLocale),
                tap(() => {
                    this.translateService.setDefaultLang(this.localeService.initLocale);
                })
            ),
        {
            dispatch: false
        }
    );

    initLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.initLocale),
            withLatestFrom(this.authStore.me$, (action, me) => ({ locale: action.locale, me })),
            switchMap(payload => {
                const setLocale$ = (locale: string) =>
                    SettingsActions.setLocale({
                        locale
                    });

                if (this.localeService.locale) {
                    this.setLocale(payload.locale, payload.me);
                    return [setLocale$(this.localeService.locale), SettingsActions.getSalesOrganizations()];
                }

                const parsedLocale = LocaleUtils.parseLocale(payload.locale);
                const isLocaleSupported = LocaleUtils.supportedLocales.some(locale => locale === parsedLocale);
                if (isLocaleSupported) {
                    this.setLocale(parsedLocale, payload.me);
                    return [setLocale$(parsedLocale), SettingsActions.getSalesOrganizations()];
                }
                const locale = LocaleUtils.defaultBrowserLocale;
                this.translateService.setDefaultLang(locale);
                this.localeService.locale = locale;
                return [setLocale$(locale), SettingsActions.getSalesOrganizations()];
            })
        )
    );

    changeLocale$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.changeLocale, SettingsActions.setLocale),
                pairwise(),
                tap(([previousAction, currentAction]) => {
                    this.localeService.locale = currentAction.locale as string;
                    this.translateService.setDefaultLang(currentAction.locale);
                    if (!currentAction.reload) {
                        return;
                    }

                    const previousCountryCode = LocaleUtils.getCountryCode(previousAction.locale);
                    const currentCountryCode = LocaleUtils.getCountryCode(currentAction.locale);

                    if (previousCountryCode === currentCountryCode) {
                        this.windowService.reload();
                        return;
                    }

                    const projectListUrl = `${this.environmentService.baseUrl}/${RoutePaths.Projects}`;
                    this.windowService.changeHref(projectListUrl);
                })
            ),
        {
            dispatch: false
        }
    );

    getSalesOrganizations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.getSalesOrganizations),
            switchMap(() => {
                return this.locationsControllerService.locationsControllerGetOrganizations();
            }),
            map(organizations => SettingsActions.getSalesOrganizationsSuccess(organizations)),
            catchError(() => {
                const message = this.translateService.instant('COMMON.ERRORS.SOMETHING_WRONG');
                const action = this.translateService.instant('COMMON.BUTTONS.GOT_IT');
                this.snackbarService.showInfo(message, {
                    duration: 5000,
                    action
                });
                return EMPTY;
            })
        )
    );

    authUserHotJarLocale$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.setLocale),
                tap(action => {
                    // Set HotJar locale for authenticated user
                    const userId = this.authService.userId;
                    this.setHotJarLocale(action.locale, userId);
                })
            ),
        {
            dispatch: false
        }
    );

    nonAuthUserHotJarLocale$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.getCsrfTokenError),
                withLatestFrom(this.settingsStore.locale$, (action, locale) => ({ locale })),
                tap(({ locale }) => {
                    // Set HotJar locale for non authenticated user
                    this.setHotJarLocale(locale!, null);
                })
            ),
        {
            dispatch: false
        }
    );

    private setLocale(locale: string, me: UserResult): void {
        const storedLocale = this.localeService.locale;
        if (storedLocale) {
            if (storedLocale === locale) {
                this.translateService.setDefaultLang(storedLocale);
                return;
            }

            if (!this.authService.isInstaller(me)) {
                return;
            }

            const [storedLanguage, storedCountry] = storedLocale.split('-');
            const [_language, country] = locale.split('-');
            if (storedCountry === country && !supportedCountryLanguages[country as CountryCode]?.includes(storedLanguage)) {
                this.translateService.setDefaultLang(locale);
                this.localeService.locale = locale;
                return;
            }

            if (storedCountry !== country) {
                this.translateService.setDefaultLang(locale);
                this.localeService.locale = locale;
            }
        } else {
            this.translateService.setDefaultLang(locale);
            this.localeService.locale = locale;
        }
    }

    private setHotJarLocale(locale: string, userId: string | null): void {
        if (!this.environmentService.hotJarEnabled) {
            return;
        }

        const applicationLanguageCode = LocaleUtils.getLanguageCode(locale);
        const applicationCountryCode = LocaleUtils.getCountryCode(locale);
        // Wait for HotJar to be loaded
        const timeout = setInterval(() => {
            // @ts-ignore
            if (this.windowService.nativeWindow.hj) {
                // @ts-ignore
                this.windowService.nativeWindow.hj('identify', userId, {
                    applicationCountryCode,
                    applicationLanguageCode
                });
                clearInterval(timeout);
            }
        }, 1000);
    }
}
